import { useContext, createContext, useEffect, useState } from "react"
import { useLoader } from "./useLoader"
import { toast } from "react-toastify"
import { trimCampos } from "../util/funcoesPadrao"
import { useAPIService } from "./useAPIService"

const SistemaContext = createContext({})
const nomeStorage = (process.env.REACT_APP_LOCAL_STORAGE ?? 'projeto-espiga')
const userKey = '_' + nomeStorage + '_user'
const ultimoUserKey = '_' + nomeStorage + '_ultimo_user'
const tokenKey = '_' + nomeStorage + '_token'

export function SistemaProvider({children}) {
  const [token, setToken] = useState(null)
  const [usuario, setUsuario] = useState({})
  const [menu, setMenu] = useState([])
  const { showLoader, hideLoader } = useLoader()
  const { post, get, atualizaToken, tokenInvalido, setTokenInvalido } = useAPIService()

  async function login(dados){
    try {
      showLoader(true)
      dados = trimCampos(dados)
      const res = await post('login', dados)

      if(res.token){
        //Atualiza token utilizado na api de conexão
        atualizaToken(res.token)

        //Chama funções sequentes
        const u = await getUser()

        if(u){
          if(await getMenus()){
            //Atualiza dados do sistema
            setToken(res.token)
            setUsuario(u)
            //Atualiza logalStorage
            setDadosLocalStorage(res.token, u)
            //Atualiza validade do token
            setTokenInvalido(false)
          } 
        } 
      } 
    } catch (error) {
      toast.error('Falha ao realizar login')
    } finally {
      hideLoader()
      return
    }
  }
  
  async function logout(loader = true){
    if(loader)
      showLoader()

    //Chamar logout no backend
    await post('logout')
    setUsuario({})
    setMenu([])
    setToken(null)
    localStorage.removeItem(tokenKey)
    localStorage.removeItem(userKey)

    if(loader)
      hideLoader()
  }
  
  async function validarToken(t){
    try {
      showLoader()
      const response = await get('check-token')

      if(response){
        //Chama funções sequentes
        const u = await getUser()

        if(u){
          if(await getMenus()){
            //Atualiza dados do sistema
            setUsuario(u)
            setToken(t)
            //Atualiza logalStorage
            setDadosLocalStorage(t, u)
          } 
        }
      }
    } catch (error) {
      toast.error('Falha ao validar token do usuário')
      logout()
    } finally {
      hideLoader()
    }
  }

  function atualizarDados(user){
    const u = {
      ...usuario, 
      ...user
    }
    setUsuario(u)
    setDadosLocalStorage(token, u)
  }

  function setDadosLocalStorage(t, u){
    localStorage.setItem(tokenKey, t)
    localStorage.setItem(userKey, JSON.stringify(u))
    //Registra último login
    localStorage.setItem(ultimoUserKey, JSON.stringify(u.usuario))
  }

  async function getUser(){
    try {
      const response = await get('user')

      if(response){
        return response
      }

      return null
    } catch (error) {
      toast.error('Falha ao buscar dados do usuário')
      return null
    }
  }

  async function getMenus(){
    try {
      const response = await get('user/menus', true)
      if(response){
        setMenu(response)
        return true
      }
      return false
    } catch (error) {
      toast.error('Falha ao buscar permissões do usuário')
      return false
    }
  }

  useEffect(() => {
    if(tokenInvalido){
      logout(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenInvalido])

  useEffect(() => {
    const t = localStorage.getItem(tokenKey)
    if(t){
      validarToken(t)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SistemaContext.Provider value={{ 
      usuario, 
      menu, 
      login, 
      logout, 
      atualizarDados,
      token
    }}>
      {children}
    </SistemaContext.Provider>
  )
}

export function useSistema() {
    const context = useContext(SistemaContext)

    return context;
}