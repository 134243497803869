import Papa from 'papaparse'
import consts from '../consts';

export function trimCampos(campos) {
    if (typeof campos !== 'object' || campos === null) return campos; // Retorna diretamente se não for objeto

    if (Array.isArray(campos)) {
        return campos.map((item) =>
            !(item instanceof File) ? trimCampos(item) : item // Não modifica arquivos
        );
    }

    // Para objetos, cria uma cópia com valores ajustados
    return Object.keys(campos).reduce((novoObj, key) => {
        const valor = campos[key];
        if (typeof valor === 'string') {
            novoObj[key] = valor.trim(); // Trim strings
        } else if (typeof valor === 'object' && valor !== null) {
            novoObj[key] = trimCampos(valor); // Recursão para objetos e arrays
        } else {
            novoObj[key] = valor; // Mantém valores não processáveis
        }
        return novoObj;
    }, {});
}

export function montaFormData(campos, nomeCampo, form = new FormData()){
    Object.keys(campos).forEach((key) => {
        //Verifica se não é arquivo
        if (!key.includes('_file')) {
            if (typeof (campos[key]) !== 'object') {
                if (typeof (campos[key]) !== 'boolean') {
                    form.append((!nomeCampo ? key : `${nomeCampo}[${key}]`), campos[key])
                } else {
                    form.append((!nomeCampo ? key : `${nomeCampo}[${key}]`), (campos[key] === true ? "1" : "0"))
                }
            } else {
                if (campos[key] !== null) {
                    campos[key].forEach((obj, index) => {
                        form = montaFormData(obj, `${key}[${index}]`, form)
                    })
                }
            }
        } else {
            campos[key].forEach((obj, index) => {
                form.append(`${(!nomeCampo ? key : `${nomeCampo}[${key}]`)}${(campos[key].length > 1 ? '_' + index : '')}`, obj)
            })
        }
    })

    return form
}

export function formatMoeda(valor, somenteNumeros = true) {
    const format = Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(valor)

    return (!somenteNumeros ? format : format.replace('R$ ', '').replace('.', ''))
}

export function formatMoedaPonto(valor) {
    return formatMoeda(valor, true).replace('.', '')
}

export function formatJSPonto(valor, casas = 2){
    if(valor !== undefined && valor != null && valor !== ""){
        if((typeof valor) == "string"){
            valor = valor.replace(',', '.');
        }
        return parseFloat(parseFloat(valor).toFixed(casas));
    } else 
        return parseFloat(0);
}

export function formatJSVirgula(valor, casas = 2){
    if(valor !== undefined && valor !== null && valor !== ""){
        if((typeof valor) === "string"){
            valor = valor.replace(',', '.');
        }
        return parseFloat(valor).toFixed(casas).replace('.', ',');
    } else 
        return parseFloat(0).toFixed(casas).replace('.', ',');
}

export function converteMoedaEmCentavos(valor = '0') {
    const [reais, centavos] = valor.split(',');
    const centavosCorrigidos = centavos !== undefined 
        ? (centavos.length === 1 ? Number(centavos) * 10 : Number(centavos))
        : 0;
    return Number(reais) * 100 + centavosCorrigidos;
}

export function formataCPF(cpf = ""){
    if(cpf !== "")
        return cpf.substring(0, 3) + "." + cpf.substring(3, 6) + "." + cpf.substring(6, 9) + "-" + cpf.substring(9)
    else
        return cpf
}

export function retiraCaracteres(string = ""){
    return string.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '').replaceAll(',', '')
}

export function gerarArquivoCSV(nomeArquivo, dados, colunas = null){
    const csv = Papa.unparse(dados, {
      quotes: true,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ";",
      header: false,
      newline: "\r\n",
      skipEmptyLines: true,
      columns: colunas //or array of strings
    })
    const csvWithBom = "\uFEFF" + csv; // Adiciona o BOM (Byte Order Mark)
    const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', nomeArquivo)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export function downloadArquivo(arquivo){
    const link = document.createElement('a')
    link.href = consts.linkRepositorio + arquivo
    link.setAttribute('download', ''); 
    link.setAttribute('target', '_blank'); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}