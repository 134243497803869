import { useSistema } from '../hooks/useSistema';
import { lazy, Suspense, useEffect } from 'react';
import '../componentes/layout/util/Custom.css'
import { LoaderSuspense } from '../componentes/layout/loader/LoaderSuspense';

const FormLogin = lazy(() => import('../modulos/login/FormLogin').then(module => {
    return { default: module.FormLogin }
}))

const App = lazy(() => import('./App').then(module => {
    return { default: module.App }
}))

const primaryColor = process.env.REACT_APP_COR_TEMA || '#007BFF' //Cor primaria AdminLTE

export function LoginOrApp(){
    const { usuario } = useSistema()

    useEffect(() => {
      if (primaryColor) {
        document.documentElement.style.setProperty('--primary-color', primaryColor);
      }
    }, []);

    return (
        <>
            <Suspense fallback={<LoaderSuspense />}>
                {((usuario.id) ? <App /> : <FormLogin />)}
            </Suspense>
        </>
    )
}