import { Rings } from "react-loader-spinner";
import { useLoader } from '../../../hooks/useLoader';

export function Loader(){
    const { loader } = useLoader()

    return (
        <>
            {((loader !== "") ?
                <div className={`overlay${(loader !== "loaderLogin" ? ' overlay-custom' : '')}`}>
                    <Rings
                        type="Puff"
                        color={process.env.REACT_APP_COR_TEMA}
                        height={100}
                        width={100}
                        visible={true} 
                    />
                </div>
            : null)}
        </>
    )
}