import './globals'
import './dependencias'
import './index.css'
import React from 'react';
import ReactDOM from 'react-dom/client';
import { LoaderProvider } from './hooks/useLoader';
import { ToastContainer } from 'react-toastify';
import { SistemaProvider } from './hooks/useSistema';
import { LoginOrApp } from './main/LoginOrApp';
import { toastifyConfig } from './consts';
import { APIServiceProvider } from './hooks/useAPIService';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <LoaderProvider>
    <ToastContainer {...toastifyConfig} />
    <APIServiceProvider>
      <SistemaProvider>
        <LoginOrApp />
      </SistemaProvider>
    </APIServiceProvider>
  </LoaderProvider>,
  // </React.StrictMode>
)
