import { createContext, useContext, useState } from "react";
import axios from 'axios'
import { toast } from 'react-toastify'

const APIServiceContext = createContext({})

export function APIServiceProvider({children}) {
  const urlServer = process.env.REACT_APP_URL_SERVIDOR
  const nomeStorage = (process.env.REACT_APP_LOCAL_STORAGE ?? 'projeto-ant')
  const tokenKey = '_' + nomeStorage + '_token'
  const authToken = localStorage.getItem(tokenKey)

  //Controle de token inválido
  const [tokenInvalido, setTokenInvalido] = useState(false)

  const api = axios.create({
    baseURL: urlServer,
    headers: { 
      'Content-Type': 'application/json', 
      'Accept': 'application/json',
      'Authorization': (authToken !== null ? 'Bearer ' + authToken : undefined)
    },
  })

  function atualizaToken(token){
    api.defaults.headers['Authorization'] = 'Bearer ' + token;
  }

  function getImagem(imagem){
    try {
      const busca = imagem ?? process.env.REACT_APP_IMAGEM_NAO_ENCONTRADA
      return `${urlServer}files/${busca}`  
    } catch (error) {
      return ""
    }
  }

  async function get(rota = '', lista = false, filtros = {}){
    try {
      const res = await api.get(`${urlServer}${rota}`, {
        params: filtros
      })

      if(lista){
        if(res.data.data){
          return res.data.data
        } else {
          return []
        }
      } else {
        if(res.data){
          return res.data
        } else {
          return true
        }
      }
    } catch (error) {
      if(error.status === 401){
        toast.error('Login expirado')
        setTokenInvalido(true)
      } else {
        if(error.response?.data?.errors){
          const errors = error.response.data.errors;

          // Percorrendo e exibindo as mensagens de erro
          for (const field in errors) {
            if (errors.hasOwnProperty(field)) {
              // Cada campo pode ter várias mensagens
              errors[field].forEach((message) => {
                toast.error(message)
              })
            }
          }
        } else if(error.response?.data?.message){
          toast.error(error.response.data.message)
        } else {
          toast.error(error.message)
        }
      }

      if(lista)
        return []
      else 
        return false
    }
  }

  async function post(rota = '', dados = {}){
    try {
      const res = await api.post(`${urlServer}${rota}`, dados)

      if(res.data){
        return res.data
      } else {
        return true
      }
    } catch (error) {
      if(error.response?.data?.errors){
        const errors = error.response.data.errors;

        // Percorrendo e exibindo as mensagens de erro
        for (const field in errors) {
          if (errors.hasOwnProperty(field)) {
            // Cada campo pode ter várias mensagens
            errors[field].forEach((message) => {
              toast.error(message)
            })
          }
        }
      } else if(error.response?.data?.message){
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }

      return false
    }
  }

  async function put(rota = '', dados){
    try {
      const res = await api.put(`${urlServer}${rota}`, dados)

      if(res.data){
        return res.data
      } else {
        return true
      }
    } catch (error) {
      if(error.response?.data?.errors){
        const errors = error.response.data.errors;

        // Percorrendo e exibindo as mensagens de erro
        for (const field in errors) {
          if (errors.hasOwnProperty(field)) {
            // Cada campo pode ter várias mensagens
            errors[field].forEach((message) => {
              toast.error(message)
            })
          }
        }
      } else if(error.response?.data?.message){
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }

      return false
    }
  }

  async function del(rota = ''){
    try {
      await api.delete(`${urlServer}${rota}`)

      return true
    } catch (error) {
      if(error.response?.data?.errors){
        const errors = error.response.data.errors;

        // Percorrendo e exibindo as mensagens de erro
        for (const field in errors) {
          if (errors.hasOwnProperty(field)) {
            // Cada campo pode ter várias mensagens
            errors[field].forEach((message) => {
              toast.error(message)
            })
          }
        }
      } else if(error.response?.data?.message){
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }

      return false
    }
  }

  async function multipart(rota = '', dados, method){
    try {
      dados.append('_method', method)
      const res = await api.post(`${urlServer}${rota}`, dados, { headers: { 'Content-Type': 'multipart/form-data' }, })

      if(res.data){
        return res.data
      } else {
        return true
      }
    } catch (error) {
      if(error.response?.data?.errors){
        const errors = error.response.data.errors;

        // Percorrendo e exibindo as mensagens de erro
        for (const field in errors) {
          if (errors.hasOwnProperty(field)) {
            // Cada campo pode ter várias mensagens
            errors[field].forEach((message) => {
              toast.error(message)
            })
          }
        }
      } else if(error.response?.data?.message){
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }

      return false
    }
  }

  return (
    <APIServiceContext.Provider value={{ 
      api, 
      atualizaToken, 
      tokenInvalido,
      setTokenInvalido,
      getImagem, 
      get, 
      post, 
      put, 
      del, 
      multipart,
    }}>
      {children}
    </APIServiceContext.Provider>
  )
}

export function useAPIService() {
    const context = useContext(APIServiceContext)

    return context;
}